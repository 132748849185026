// user 選單 - 任務相關按鈕

const UserMenuTaskButtons = ({ onCheckTask, isRewardNotReceived, cmOrigin, t, showTask }) => {
    if (!showTask)
        return (
            <div className="mt-[16px] flex items-center gap-x-[6px] [&>*]:rounded-[4px] [&>*]:text-center [&>*]:py-[7px] [&>*]:w-full [&>*]:text-[#fff] [&>*]:text-btn1 [&>*]:trans">
                {/* 任務列表、領取獎勵 */}
                <button
                    onClick={onCheckTask}
                    className={`bg-[#00445d] hover:bg-[#1e1e1e] dark:hover:bg-[#4B4B4B] ${
                        isRewardNotReceived?.length ? 'task-notice' : ''
                    }`}
                >
                    {t(isRewardNotReceived?.length ? 'EARN' : 'TASKS')}
                </button>

                {/* 獎勵兌換 => 會員系統 */}
                <a
                    href={cmOrigin + 'exchange'}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="bg-[#29afcb] hover:bg-[#1e1e1e] dark:hover:bg-[#4B4B4B]"
                >
                    {t('EXCHANGE')}
                </a>
            </div>
        );

    return <></>;
};

export default UserMenuTaskButtons;
